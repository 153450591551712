<template>
  <div class="content-body">
    <div class="academic-container">
        <div class="row mg-0">
        <div class="col-sm-5">
            <div class="content-header pd-l-0">
            <div>
                <h4 class="content-title content-title-xs">Manage Bank</h4>
            </div>
            </div>
            <!-- content-header -->
        </div>
      </div>
        <!-- row -->
        <div class="row my-2">
            <div class="filter-input col-md-9 d-flex mr-0 pr-0">
            <select v-model="filter.size">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
            </select>
            </div>
            <div class="col-md-3 ml-0 pl-0">
            <input
                type="text"
                v-model="filter.key"
                class="float-right px-1"
                placeholder="Search..."
            />
            </div>
        </div>
        <div class="row py-0">
        <div class="col-md-12 year-transfer-table-body">
            <table class="table table1 table-hover" id="tableData">
            <thead>
                <tr>
                <th class="wd-5p table-start-item">S.N</th>
                <th class="wp-30p">Bank Name</th>
                <th class="wo-15p">Bank Branch</th>
                <th class="wd-25p">Account No</th>
                <th class="wd-15p">Balance Amount</th>
                <th class="wd-10p text-right table-end-item">Action</th>
                </tr>
            </thead>
            <tbody v-for="(bank, index) in dataSets" :key="index">
                <tr v-if="!removeList.includes(bank.id)">
                <th scope="row" class="table-start-account">{{ ++index }}</th>
                <td>{{bank.bank}}</td>
                <td>{{bank.bank_branch}}</td>
                <td>{{bank.account_no}}</td>
                <td>NPR: {{bank.opening_balance}}</td>
                <td class="text-right table-end-item" >
                    <a href="javascript:;" @click="drop(bank.id)">
                    <i class="fa fa-trash"></i>
                    </a>
                </td>
                </tr>
            </tbody>
            </table>
            <Pagination />
        </div>
        <div class="col-md-12 text-right mt-3">
            <button type="submit" class="btn btn-sm btn-primary" @click="$router.push(`/academic-year-transfer/capital`)">Next</button>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {Search} from "../../../../../../../mixins/search";
import Pagination from "../../../../../components/pagination/pagination";
export default {
  mixins: [Search],
  components:{
    Pagination
  },
  methods: {
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/bank/${id}`);
    },filterByType(){
      if(this.dataLists.length!=0) {
        let branch = this.filter.type;
        let details = this.dataLists.filter(function (data) {
          return data.branch == branch;
        });
        this.dataSets = {...details};
      }
    },
    getData(){
      this.$store.commit("getData", `api/banks/size/${this.filter.size}`);
    }
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists1",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },mounted() {
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    }
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>